<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar
          :title="pageTitle"
          left-arrow
          @click-left="goBack()"
        >
          <template #right v-if="worklog.creatorId == $store.state.user.id">
            <div>
              <van-icon name="edit" @click="toedit()" size="14"
                ><span style="position: relative; top: -2px"
                  >修改{{ templateTitle || "总结" }}</span
                ></van-icon
              >
            </div>
          </template>
        </van-nav-bar>
      </van-sticky>
      <div class="detail">
        <van-row>
          <van-col span="24">
            <van-col span="3">
              <van-image
                style="float: left"
                round
                width="41px"
                height="41px"
                fit="fill"
                :src="worklog.headImgurl"
                @click.stop="toUserHomePage(worklog.creatorId)"
              />
            </van-col>

            <van-col span="20">
              <div
                style="
                  float: left;
                  color: #069;
                  font-size: 16px;
                  font-weight: bold;
                  width: 100%;
                "
              >
                {{ worklog.trueName }}
                <van-tag mark plain color="#9d1d22"
                  >{{ worklog.departName }}
                </van-tag>
                <userIcon :iconstring="worklog.icon"></userIcon>
              </div>

              <div style="float: left; color: #696969">
                {{ worklog.creationTime | dateFormat("YYYY/MM/DD HH:mm") }}
                <span style="margin-left: 10px"
                  >浏览{{ worklog.readCount }}人次</span
                >
              </div>
            </van-col>
            <van-col span="10"> </van-col>
          </van-col>
        </van-row>

        <van-row>
          <van-col span="24">
            <div class="acontent" v-html="worklog.detailsHtml"></div>
          </van-col>
        </van-row>
      </div>
      <comment :CommentPage="CommentPage"></comment>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { get } from "@/api/worklog";
import Comment from "../components/comment";
import UserIcon from "../components/userIcon";

export default {
  data() {
    return {
      templateTitle: "",
      loading: true,
      worklog: {
        id: "",
      },
      CommentPage: {
        current: 1,
        pageSize: 10,
        filterData: {
          itemId: "",
          itemType: 12,
        },
      },
    };
  },
  components: {
    Comment,
    UserIcon,
  },
  created() {
    this.onLoad();
  },
  mounted() {},
  computed: {
    pageTitle() {
      return "查看" + (this.templateTitle || "总结");
    },
  },
  updated() {
    this.addpreview();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/worklog/detail" && to.query != from.query) {
        this.loading = true;
        this.onLoad();
      }
    },
  },
  methods: {
    goBack(){
      if(this.$route.query.back){
        this.$router.back();
      }
      else{
        this.$router.replace({ path: '/worklog'});
      }
    },
    async onLoad() {
      let para = this.$route.query;
      if (!para) {
        return;
      }

      this.templateTitle = para.templateTitle;

      let d = await get(para); //({ ...this.page })
      if (!d.data.success) {
        Toast(d.data.errorMessage);
        return;
      }
      this.worklog = d.data.data;
      this.CommentPage.filterData.itemId = this.worklog.id;

      this.loading = false;
    },
    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview", this.worklog.id);
        });
      });
      this.$previewRefresh();
    },
    toedit() {
      this.$router.push({
        path: "/worklog/add",
        query: { id: this.worklog.id },
      });
    },
    toUserHomePage(id) {
      this.$router.push({
        path: "/userHomepage",
        query: { para: id },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 10px 10px 20px;
}
.acontent {
  line-height: 25px;
  /deep/ ol {
    list-style: decimal !important;
    margin-left: 25px;
  }
  /deep/ ol li {
    list-style-position: outside;
  }
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
  /deep/ p {
    padding: revert;
    margin: revert;
  }
}
</style>